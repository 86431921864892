import type { GetServerSideProps, NextPage } from 'next';

import { createSspForVertical, type SspProps } from '@/util/verticalSsp';
import { verticalConfig } from '@/config/verticalConfig';

import { Verticals } from '@/components/Verticals';

const FrontPageHotel: NextPage<SspProps> = (pageProps) => <Verticals config={verticalConfig.hotel} {...pageProps} />;

export const getServerSideProps: GetServerSideProps<SspProps> = createSspForVertical(verticalConfig.hotel.vertical);

export default FrontPageHotel;
